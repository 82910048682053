import React, { Component } from "react";
import checkimg from "../images/checkmark.png";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {a11yDark} from "react-syntax-highlighter/dist/esm/styles/prism";

class SelectiveCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiData: null,
            isCopiedRequestedURL: false,
            isCopiedHeader: false,
            isCopiedRequest: false,
            selectid: null, // Initialize selectid to null
        };
        this.codeBlockRef = React.createRef();
        this.headerRef = React.createRef();
        this.requestRef = React.createRef();
        this.responseRef = React.createRef();
        this.callbackResponseRef = React.createRef();
        this.exampleRef = React.createRef();
    }   

   componentDidMount() {
        this.fetchData(); // Fetch data when component mounts
    }

    componentDidUpdate(prevProps) {
        // Fetch data when the id prop changes
        if (prevProps.id !== this.props.id) {
            this.fetchData();
        }
    }

    handleCopyClick = (snippetName, ref, setIsCopiedState) => {
        const range = document.createRange();
        range.selectNode(ref.current);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();

        // Display "Copied!" message for a short duration
        setIsCopiedState(true);
        setTimeout(() => {
            setIsCopiedState(false);
        }, 1500);
    };

    fetchData = async () => {
        try {
            const response = await fetch(`https://centpays.com/apidoc/details/${this.props.id}`);
            const data = await response.json();
            console.log("Hello", data)
            this.setState({ apiData: data });
            if (data && data.data && data.data.length > 0) {
                this.setState({
                    headerHtml: data.data[0].header,
                    detailsHtml: data.data[0].details,
                    requestHtml: data.data[0].request,
                    responseHtml: data.data[0].response,
                    exampleHtml: data.data[0].example
                });
            } else {
                console.error("API response is missing expected data structure:", data);
            }
        } catch (error) {
            console.error("Error fetching API:", error);
        }
    };


    render() {
        const {
            apiData,
            headerHtml,
            detailsHtml,
            requestHtml,
            responseHtml,
            isCopiedRequestedURL,
            isCopiedHeader,
            isCopiedRequest
        } = this.state;

        return (
            <>
                {apiData ? (
                    <div>
                        {apiData.data.map((item, index) => (
                            <div key={index}>
                                { item.url && item.url.trim() !== '' && <div className="codeBlock">
                                    <div className="codeBlock-header">
                                        <h1>URL</h1>
                                        <i
                                            className="fa-regular fa-clipboard"
                                            onClick={() => this.handleCopyClick("baseUrl", this.codeBlockRef, () => this.setState({ isCopiedRequestedURL: true }))}
                                        ></i>
                                        {isCopiedRequestedURL && (
                                            <span className="copied-message">
                                                <p>
                                                    <img src={checkimg} className="icon" alt="check icon" />
                                                    Copied!
                                                </p>
                                            </span>
                                        )}
                                    </div>
                                    <div className="codeBlock-body" ref={this.codeBlockRef}>
                                        <pre>
                                            <SyntaxHighlighter
                                                language="http"
                                                style={a11yDark}
                                                customStyle={{ background: "transparent", padding: 0 }}
                                            >
                                            {item.url}
                                            </SyntaxHighlighter>
                                        </pre>
                                    </div>
                                </div>
                                }

                                {item.header && item.header.trim() !== '' && <div className="codeBlock">
                                    <div className="codeBlock-header">
                                        <h1>Header</h1>
                                        <i
                                            className="fa-regular fa-clipboard"
                                            onClick={() => this.handleCopyClick("Header", this.headerRef, () => this.setState({ isCopiedHeader: true }))}
                                        ></i>
                                        {isCopiedHeader && (
                                            <span className="copied-message">
                                                <p>
                                                    <img src={checkimg} className="icon" alt="check icon" />
                                                    Copied!
                                                </p>
                                            </span>
                                        )}
                                    </div>
                                    <div style={{ overflow: "auto" }} className="codeBlock-body" ref={this.headerRef}>
                                        <div dangerouslySetInnerHTML={{ __html: headerHtml }} />
                                    </div>
                                </div>
                                }
                                
                                { item.request && item.request.trim() !== ''  && <div className="codeBlock">
                                    <div className="codeBlock-header">
                                        <h1>Request</h1>
                                        <i
                                            className="fa-regular fa-clipboard"
                                            onClick={() => this.handleCopyClick("Request", this.requestRef, () => this.setState({ isCopiedRequest: true }))}
                                        ></i>
                                        {isCopiedRequest && (
                                            <span className="copied-message">
                                                <p>
                                                    <img src={checkimg} className="icon" alt="check icon" />
                                                    Copied!
                                                </p>
                                            </span>
                                        )}
                                    </div>
                                    <div className="codeBlock-body" ref={this.requestRef}>
                                        <div dangerouslySetInnerHTML={{ __html: requestHtml }} />
                                    </div>
                                </div>
                                }
                                
                                { item.response && item.response.trim() !== ''  && <div className="textBlock">
                                    <div className="textBlock-header">
                                        <h1>Response</h1>
                                    </div>
                                    <div className="textBlock-body" ref={this.responseRef}>
                                        <div dangerouslySetInnerHTML={{ __html: responseHtml }} />
                                    </div>
                                </div>
                                }

                                { item.details && item.details.trim() !== ''  && <div className="textBlock">
                                    <div className="textBlock-header">
                                        <h1>Details</h1>
                                    </div>
                                    <div className="textBlock-body" ref={this.callbackResponseRef}>
                                        <div dangerouslySetInnerHTML={{ __html: detailsHtml }} />
                                    </div>
                                </div>
                                }
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>Loading data...</p>
                )}
            </>
        );
    }
}

export default SelectiveCode;
