import React, { Component } from "react";
import "./apistyle.css";
import cplogo from "./images/centpays_full_logo.png";

// Data
import SelectiveData from "./apidata/Selective_Data";

// Code
import SelectiveCode from "./apicode/Selective_Code";

class Apidoc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMenuOpen: true,
			apiData: null,
			activeItem: localStorage.getItem("activeItem") || '12', // Retrieve activeItem from localStorage or set default to '12'
		};
	}

	componentDidMount() {
		fetch("https://centpays.com/apidoc/get_all")
			.then((response) => response.json())
			.then((data) => {
				this.setState({ apiData: data });
			})
			.catch((error) => {
				console.error("Error fetching API data:", error);
			});
	}

	componentDidUpdate(prevProps, prevState) {
		// Update localStorage when activeItem changes
		if (prevState.activeItem !== this.state.activeItem) {
			localStorage.setItem("activeItem", this.state.activeItem);
		}
	}

	handleOptionClick = (index) => {
		this.handleToggleMenu();
		this.setState({ activeItem: index });
	};

	handleToggleMenu = () => {
		this.setState((prevState) => ({
			isMenuOpen: !prevState.isMenuOpen,
		}));
	};

	render() {
		const { activeItem, isMenuOpen, apiData } = this.state;

		return (
			<>
				{apiData ? (
					<div className="apicdoc">
						<div className="background"></div>
						<div className="api-body">
							<div className={`api-sidebar ${isMenuOpen ? "open" : ""}`}>
								<div className="api-sidebar-top">
									<img
										src={cplogo}
										alt="Company logo"
										className={`companylogo ${isMenuOpen ? "hidden" : ""}`}
									/>
									<button onClick={() => this.handleToggleMenu()}>
										{isMenuOpen ? ">" : "<"}
									</button>
								</div>
								<div className={`api-sidebar-middle ${isMenuOpen ? "hidden" : ""}`}>
									<ul>
										{apiData &&
											apiData.data.map((option, index) => (
												<li
													key={index}
													className={`sidebar-options ${
														activeItem === option.id ? "active" : ""
													}`}
													onClick={() => this.handleOptionClick(option.id)}
												>
													{option.menu_name}
												</li>
											))}
									</ul>
								</div>
								<div className="api-sidebar-bottom">
									<p className={` ${isMenuOpen ? "hidden" : ""}`}>
										<a href="https://centpays.com/">Sign in</a>
									</p>
								</div>
							</div>
							<span>
								<div className="api-header">
									<ul>
										<li>
											<a href="https://centpays.com/">Login</a>
										</li>
										<li>
											<a href="https://centpays.com/">Sing in</a>
										</li>
									</ul>
								</div>
								<div
									className={`api-main-component ${
										activeItem === "12" ? "api-main-component-intro-display" : ""
									}`}
								>
									<div
										className={`api-compartment-left ${
											activeItem === "12" ? "intro-display" : ""
										}`}
									>
										<div>
											<SelectiveData id={this.state.activeItem} />
										</div>
									</div>
									<div className="api-compartment-right">
										<div>
											<SelectiveCode id={this.state.activeItem} />
										</div>
									</div>
								</div>
							</span>
						</div>
					</div>
				) : (
					<p>Loading API data...</p>
				)}
			</>
		);
	}
}

export default Apidoc;