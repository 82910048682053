import React, { Component } from 'react';

class SelectiveData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiData: null,
        };
    }

    componentDidMount() {
        this.fetchData();   
    }

    componentDidUpdate(prevProps) {
        // Fetch data when the id prop changes
        if (prevProps.id !== this.props.id) {
            this.fetchData();
        }
    }

    fetchData = async () => {
        try {
            const response = await fetch(`https://centpays.com/apidoc/details/${this.props.id}`);
            const data = await response.json();
            this.setState({ apiData: data });
           
        } catch (error) {
            console.error('Error fetching API:', error);
        }
    };

    render() {
        const { apiData } = this.state;

        return (
            <>
                {apiData && apiData.data ? (
                    apiData.data.map((item, index) => (
                        <div key={index} className="centpays-container">
                            <h2>{item.menu_name}</h2>
                            {item.description && (
                                <div className="centpays-container-intro">
                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p>Loading...</p>
                )}
            </>
        );
    }
}

export default SelectiveData;
